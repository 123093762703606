<template>
  <div class="grid">
    <!-- Card de Relatório Tracking -->
    <div class="col-6">
      <div class="card">
        <h5>Importar Relatório Tracking</h5>
        <Toolbar class="mb-4">
          <template v-slot:end>
            <Button label="CSV Tracking" icon="pi pi-upload" class="p-button-success" @click="importarTracking" />
          </template>
        </Toolbar>
        <hr>
        <div style="width: 100%; overflow: auto;">
          <h6>
             Acesse aqui o exemplo de arquivo de upload de Relatório Tracking:
            <a href="/app/front/uploadTracking.csv" download>Clique Aqui</a>
          </h6>
          <hr />
        </div>
      </div>
    </div>

    <!-- Card de Relatório Gedi -->
    <div class="col-6">
      <div class="card">
        <h5>Importar Relatório Gedi</h5>
        <Toolbar class="mb-4">
          <template v-slot:end>
            <Button label="CSV Gedi" icon="pi pi-upload" class="p-button-success" @click="importarGedi" />
          </template>
        </Toolbar>
        <hr>
        <div style="width: 100%; overflow: auto;">
          <h6>
             Acesse aqui o exemplo de arquivo de upload de relatório Gedi:
            <a href="/app/front/uploadGedi.csv" download>Clique Aqui</a>
          </h6>
          <hr />
        </div>
      </div>
    </div>

    <!-- Modal para Relatório Tracking -->
    <Dialog v-model:visible="modalTracking" :style="{ width: '600px' }" :modal="true" header="Enviar relatório?">
      <p>Deseja enviar o relatório?</p>
      <div class="flex align-items-center justify-content-center">
        <div class="field">
          <FileUpload 
            chooseLabel="Selecionar arquivo csv" 
            ref="arquivoTracking" 
            mode="basic" 
            name="arquivoTracking"
            @change="onChangeFileUploadTracking" />
        </div>
      </div>
      <template #footer>
        <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="modalTracking = false" />
        <Button label="Enviar" icon="pi pi-check" class="p-button-success p-button-sm" @click="enviarTracking" />
      </template>
    </Dialog>

    <!-- Modal para Relatório Gedi -->
    <Dialog v-model:visible="modalGedi" :style="{ width: '600px' }" :modal="true" header="Enviar relatório?">
      <p>Deseja enviar o relatório?</p>
      <div class="flex align-items-center justify-content-center">
        <div class="field">
          <FileUpload 
            chooseLabel="Selecionar arquivo csv" 
            ref="arquivoGedi" 
            mode="basic" 
            name="arquivoGedi"
            @change="onChangeFileUploadGedi" />
        </div>
      </div>
      <template #footer>
        <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="modalGedi = false" />
        <Button label="Enviar" icon="pi pi-check" class="p-button-success p-button-sm" @click="enviarGedi" />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalTracking: false,
      modalGedi: false,
      arquivoTracking: null,
      arquivoGedi: null,
    };
  },
  methods: {
    importarTracking() {
      this.modalTracking = true;
    },
    importarGedi() {
      this.modalGedi = true;
    },
    onChangeFileUploadTracking() {
      this.arquivoTracking = this.$refs.arquivoTracking.files[0];
    },
    onChangeFileUploadGedi() {
      this.arquivoGedi = this.$refs.arquivoGedi.files[0];
    },
    enviarTracking() {
      let formData = new FormData();
      formData.append("arquivo", this.arquivoTracking);
      
      this.axios
        .post("/relatorios/upload-relatorio-tracking", formData)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Relatório Tracking importado com sucesso",
            life: 3000,
          });
          this.modalTracking = false;
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Erro ao importar relatório Tracking",
            life: 3000,
          });
        });
    },
    enviarGedi() {
      let formData = new FormData();
      formData.append("arquivo", this.arquivoGedi);
      
      this.axios
        .post("/relatorios/upload-relatorio-gedi", formData)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Relatório Gedi importado com sucesso",
            life: 3000,
          });
          this.modalGedi = false;
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Erro ao importar relatório Gedi",
            life: 3000,
          });
        });
    },
  },
};
</script>

<style scoped>
.grid {
  margin: 20px 0;
}
</style>